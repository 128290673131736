<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Delivery - Listar</strong>
          </CCardHeader>
          <CCardBody>

            <b-row>
              <b-col sm="12" md="5">
                <b-form-group>
                  <label>Cliente: </label>
                  <v-select @input="ListDelivery"  placeholder="Todos" class="w-100" :filterable="false" label="full_name" v-model="client" @search="SearchClients" :options="clients"></v-select>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="2">
                <b-form-group label="Desde :">
                  <b-form-input @change="ListDelivery" class="text-center" :max="to" type="date"  ref="to" v-model="from"></b-form-input>
                  <small v-if="errors.from" class="form-text text-danger" >Selccione una fecha</small>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="2">
                <b-form-group label="Hasta :">
                  <b-form-input @change="ListDelivery" class="text-center" :min="from" type="date"  ref="from" v-model="to"></b-form-input>
                  <small v-if="errors.to" class="form-text text-danger" >Selccione una fecha</small>
                </b-form-group>
              </b-col>
              
              <b-col sm="6" md="2">
                <b-form-group label=".">
                  <b-input-group>
                  <b-form-input type="search" v-model="search" class="form-control"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" @click="ListDelivery"><b-icon icon="search"></b-icon></b-button>
                  </b-input-group-append>
                </b-input-group>
                </b-form-group>
              </b-col>

              <b-col sm="6" md="1">
                <b-form-group label=".">
                  <b-link  v-if="Permission('DeliveryAdd')" class="btn form-control btn-primary"  :to="{ path: '/delivery/nuevo' }" append ><i class="fas fa-file"></i></b-link>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="8%" class="text-center">Fecha</th>
                    <th width="12%" class="text-center">Documento</th>
                    <th width="50%" class="text-center">Cliente</th>
                    <th width="7%" class="text-center">Moneda</th>
                    <th width="8%" class="text-center">Total</th>
                    <th width="5%" class="text-center">Estado</th>
                    <th width="7%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="item.id_delivery">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.date }}</td>
                    <td class="text-center"> {{ item.code }}</td>
                    <td class="text-left"> {{ item.client_name + ' - '+item.client_document_number }}</td>
                    <td class="text-center"> {{ item.coin}} </td>
                    <td class="text-right"> {{ item.total}} </td>
                    <td class="text-center">
                      <b-badge v-if="item.state == 1" variant="info">Pendiente</b-badge>
                      <b-badge v-if="item.state == 2" variant="success">Realizado</b-badge>
                      <b-badge v-if="item.state == 0" variant="danger">Anulado</b-badge>
                    </td>
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        
                        <b-dropdown-item v-if="Permission('DeliveryEdit')  && item.state == 1" @click="EditDelivery(item.id_delivery)" >Editar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('DeliveryEdit')  && (item.state == 1 || item.state == 2)" @click="Invoice(item.id_delivery)" >Facturar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('DeliveryEdit')  && item.state == 1" @click="ConfirmFinalizeDelivery(item.id_delivery)" >Finalizar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('DeliveryView')"  @click="ViewDelivery(item.id_delivery)" >Ver</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('DeliveryDelete') && item.state == 1" @click="ConfirmDeleteDelivery(item.id_delivery)">Eliminar</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage"  v-on:input="ListDelivery"  :total-rows="rows"  :per-page="perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");

export default {
  name: "EntradaList",
  components:{
      vSelect,
  },
  data() {
    return {
      module: 'Delivery',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      search: "",
      data_table: [],

      to:moment(new Date()).local().format("YYYY-MM-DD"),
      from:moment().subtract(30, 'days').local().format("YYYY-MM-DD"),
      search: "",
      clients: [],
      client:null,
      errors:{
        to:false,
        from:false,
      }
    };
  },
  mounted() {
    this.ListDelivery();
  },
  methods: {
    ListDelivery,
    EditDelivery,
    ViewDelivery,
    ConfirmDeleteDelivery,
    DeleteDelivery,
    Permission,
    CodeInvoice,
    NameMethodPayment,
    SearchClients,
    Invoice,

    ConfirmFinalizeDelivery,
    FinalizeDelivery,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function Invoice(id_delivery) {
  this.$router.push({
    name: "SaleAddDelivery",
    params: { id_delivery: je.encrypt(id_delivery) },
  });
}


function SearchClients(search, loading) {
   let me = this;
    let url = this.url_base + "search-clients/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.clients = response.data;
            loading(false);
      })
    }
}

function CodeInvoice(code) {
  return CodeToName.CodeInvoice(code);
}

function NameMethodPayment(code) {
  return CodeToName.NameMethodPayment(code);
}

//listar usuario
function ListDelivery() {
  
  this.errors.from = false;
  this.errors.to = false;

  let id_client = this.client == null ? 'all' : this.client.id;
  if (this.from.length == 0) {this.errors.from = true; return false;}
  if (this.to.length == 0) {this.errors.to = true; return false;}
  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url = this.url_base + "delivery/list/" + me.user.id_user +"/" + id_client + "/" + this.from + "/" + this.to + "/" + search + "?page=" + this.currentPage;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: 1},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// Editar usuario
function EditDelivery(id_delivery) {
  this.$router.push({
    name: "DeliveryEdit",
    params: { id_delivery: je.encrypt(id_delivery) },
  });
}

// Ver Usuario
function ViewDelivery(id_delivery) {
  this.$router.push({
    name: "DeliveryView",
    params: { id_delivery: je.encrypt(id_delivery) },
  });
}

// Confirmar eliminar
function ConfirmDeleteDelivery(id_delivery) {
  Swal.fire({
    title: "Esta seguro de eliminar el delivery?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteDelivery(id_delivery);
    }
  });
}

// eliminar usuario
function DeleteDelivery(id_delivery) {
  let me = this;
  let url = this.url_base + "delivery/delete/" + id_delivery;
  axios({
    method: "delete",
    url: url,
    headers: {token: this.token, module: this.module,role: 4},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        const index = me.data_table.map(item => item.id_delivery).indexOf(response.data.result.id_delivery);
        me.data_table.splice(index, 1);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error',  text: 'A ocurrido un error',  timer: 2000, })
    });
}


function ConfirmFinalizeDelivery(id_delivery) {
  Swal.fire({
    title: "Esta seguro de finalizar el delivery?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.FinalizeDelivery(id_delivery);
    }
  });
}

// eliminar usuario
function FinalizeDelivery(id_delivery) {
  let me = this;
  let url = this.url_base + "delivery/finalize/" + id_delivery;
  axios({
    method: "get",
    url: url,
    headers: {token: this.token, module: this.module,role: 3},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        const index = me.data_table.map(item => item.id_delivery).indexOf(response.data.result.id_delivery);
        me.data_table[index].state =response.data.result.state;
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error',  text: 'A ocurrido un error',  timer: 2000, })
    });
}




// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
